<mat-card>
  <mat-card-header>
    <mat-card-title class="mb-3">
      <h1>{{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_claims' | translate }}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <app-claim-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleFilters($event)"
                            [onlyRelated]="false" [type]="'claim'" [newPartner]="0">
    </app-claim-list-filters>
    <!-- Table List -->
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!--If all are selected and there is more to select on another page -->
        <div *ngIf="caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
          <div class="col-12">
            <div class="global-select-all">
              <p class="m-0" *ngIf="!caseListFilter.select_all">
                Selected <b>{{ selection.selected.length }}</b> cases on this page.
                <button mat-button color="primary" (click)="globalSelectAll($event)">
                  Select all {{ totalResults | number }} cases
                </button>
              </p>
              <p class="m-0" *ngIf="caseListFilter.select_all">
                All <b>{{ totalResults | number }}</b> cases selected.
                <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
              </p>
            </div>
          </div>
        </div>
        <mat-table [dataSource]="cases" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- Selection -->
          <ng-container matColumnDef="select">
            <!-- Select All On Page -->
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                            [checked]="selection.hasValue() && allRowsSelected()"
                            [indeterminate]="selection.hasValue() && !allRowsSelected()">
              </mat-checkbox>
            </th>
            <!-- Select Row -->
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="selection.isSelected(element)"
                            (change)="toggleRow($event, element)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Batch -->
          <ng-container matColumnDef="distribution_batch_name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.model.name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.distribution?.batch">
                <div>{{ element.distribution.batch?.name }}</div>
                <div><span class="badge badge-info">{{ element.distribution?.batch?.status }}</span></div>
              </span>
              <span *ngIf="!element.distribution?.batch" class="badge badge-danger">N/A</span>
            </td>
          </ng-container>
          <!-- Ref Number -->
          <ng-container matColumnDef="ref_number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "CASE_CREDITOR.model.reference_number" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <a [routerLink]="['/cases', element.id]">{{ element.reference_number }}</a>
            </td>
          </ng-container>
          <!-- First Name -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "USERS.model.first_name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case.client.first_name }} </td>
          </ng-container>
          <!-- Last Name -->
          <ng-container matColumnDef="last_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ 'USERS.model.last_name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case.client.last_name }}</td>
          </ng-container>
          <!-- Application Type -->
          <ng-container matColumnDef="joint_application">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              {{ "CASES.model.joint_application" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.case.joint_application" class="text-success mat-icon">check</mat-icon>
              <mat-icon *ngIf="!element.case.joint_application" class="text-danger mat-icon">clear</mat-icon>
            </td>
          </ng-container>
          <!-- Product Group Slug -->
          <ng-container matColumnDef="product_group_slug">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "PRODUCT.model.group_slug" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case.product.group_slug | uppercase }}</td>
          </ng-container>
          <!-- Product Name -->
          <ng-container matColumnDef="product_name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ "PRODUCT.model.name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case.product.name }}</td>
          </ng-container>
          <!-- Status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "CASES.case-status" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div><span class="badge badge-primary">{{ element.case.status?.name }}</span></div>
              <div class="mb-2"><span class="badge badge-info">{{ element.case.payment_status?.name }}</span></div>
            </td>
          </ng-container>
          <!-- Entered at -->
          <ng-container matColumnDef="entered_at">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.cases.list.table.entered_at' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.distribution?.entered_at | date }}</td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && cases && cases.data.length < 1" style="padding: 50px">
          <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="footer-actions ml-3">
          <button mat-raised-button color="primary" [matMenuTriggerFor]="removeFromDistMenu" class="ml-3"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label' | translate }}
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #removeFromDistMenu="matMenu">
            <div>
              <a mat-menu-item class="dropdown-item" (click)="removeFromDistributionPrompt()">
                {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label' | translate }}
              </a>
<!--              <a mat-menu-item class="dropdown-item" (click)="removeFromDistributionPrompt(1)">-->
<!--                {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label_mark_nonviable' | translate }}-->
<!--              </a>-->
            </div>
          </mat-menu>
          <button mat-raised-button color="primary" class="ml-3" (click)="assignToBatchModal()"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.assign_batch.label' | translate }}
          </button>
          <button mat-raised-button color="primary" class="ml-3" (click)="unassignFromBatchesPrompt()"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.remove_batch.label' | translate }}
          </button>
<!--          <button mat-raised-button color="primary" [matMenuTriggerFor]="reportsMenu" class="ml-3"-->
<!--                  [disabled]="selection.selected.length === 0">-->
<!--            {{ 'DISTRIBUTION.cases.list.actions.generate_report.label' | translate }}-->
<!--            <mat-icon>keyboard_arrow_down</mat-icon>-->
<!--          </button>-->
          <mat-menu #reportsMenu="matMenu">
            <!--<div>-->
            <!--  <a mat-menu-item class="dropdown-item" (click)="generateReport('scheduled_fee')">-->
            <!--    {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.scheduled_fee' | translate }}-->
            <!--  </a>-->
            <!--</div>-->
            <!--<div>-->
            <!--  <a mat-menu-item class="dropdown-item" (click)="generateReport('payments_received')">-->
            <!--    {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.payments_received' | translate }}-->
            <!--  </a>-->
            <!--</div>-->
            <div>
              <a mat-menu-item class="dropdown-item" (click)="generateReport(caseListFilter, 'claims')">
                {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.claims' | translate }}
              </a>
              <a mat-menu-item class="dropdown-item" (click)="generateReport(caseListFilter, 'pledge')">
                {{ 'DISTRIBUTION.report.pledge_report.label' | translate }}
              </a>
            </div>
          </mat-menu>
        </div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
