<div class="col-6" *ngIf="filterType !== 'legal'">
    <button mat-raised-button color="primary" (click)="newCasePrefill()">
      + {{ "CASES.add-new" | translate }}
    </button>
    <button mat-stroked-button color="primary" class="ml-2" (click)="clearFilters()">
      {{"SHARED.reset-filters" | translate}}
    </button>
  </div>
  <app-case-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleFilters($event)"
                        [onlyRelated]="onlyRelated" [type]="filterType" [newPartner]="newPartner">
  </app-case-list-filters>
  
  <!-- Bulk Notifications Form -->
  <div class="d-flex justify-content-between" *ngIf="filterType !== 'legal'">
    <form *ngIf="bulkActionForm && (authUser.role_id === 1 || authUser.role_id === 5)" [formGroup]="bulkActionForm"
          #ngBulkForm
          (ngSubmit)="sendNotifications(bulkActionForm)">
      <mat-form-field appearance="standard">
        <mat-label>{{ "CASES.list.notifications.label" | translate }}</mat-label>
        <mat-select id="action" formControlName="action">
          <mat-option value="">{{ "CASES.list.notifications.options.none" | translate }}</mat-option>
          <mat-option value="sms">{{ "CASES.list.notifications.options.sms" | translate }}</mat-option>
          <mat-option value="email">{{ "CASES.list.notifications.options.email" | translate }}</mat-option>
          <mat-option value="all">{{ "CASES.list.notifications.options.all" | translate }}</mat-option>
        </mat-select>
        <div *ngIf="bulkActionForm.get('action').errors && ngBulkForm.submitted">
          <mat-error [hidden]="!bulkActionForm.get('action').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
      <button mat-raised-button class="ml-3" type="submit" color="primary"
              [disabled]="bulkActionForm.invalid || selection.selected.length === 0">
        {{ 'SHARED.send' | translate }}
      </button>
    </form>
    <div class="text-right">
      <button mat-raised-button color="primary" (click)="applyFilters()">
        {{ 'SHARED.apply_filters' | translate }}
      </button>
    </div>
  </div>
  <!--Apply button on legal page-->
  <div class="text-right mb-3" *ngIf="filterType === 'legal'">
    <button mat-raised-button color="primary" (click)="applyFilters()">
      {{ 'SHARED.apply_filters' | translate }}
    </button>
  </div>
  <!--If all are selected and there is more to select on another page -->
  <div *ngIf="filtersReady && caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
    <div class="col-12">
      <div class="global-select-all">
        <p class="m-0" *ngIf="!caseListFilter.select_all">
          Selected <b>{{ selection.selected.length }}</b> cases on this page.
          <button mat-button color="primary" (click)="globalSelectAll($event)">
            Select all {{ totalResults | number }} cases
          </button>
        </p>
        <p class="m-0" *ngIf="caseListFilter.select_all">
          All <b>{{ totalResults | number }}</b> cases selected.
          <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="filtersReady && filterType !== 'customer_contact'; else contactTable" class="mat-elevation-z8">
    <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id]"></tr>
      <!-- Selection -->
      <ng-container matColumnDef="select">
        <!-- Select All On Page -->
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                        [checked]="selection.hasValue() && allRowsSelected()"
                        [indeterminate]="selection.hasValue() && !allRowsSelected()">
          </mat-checkbox>
        </th>
        <!-- Select Row -->
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                        (change)="toggleRow($event, row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Ref Number -->
      <ng-container matColumnDef="ref_number">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.ref_number }} </td>
      </ng-container>
      <!-- First Name -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
      </ng-container>
      <!-- Last Name -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
      </ng-container>
      <!-- Product Group Slug -->
      <ng-container matColumnDef="product_group_slug">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.group_slug" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.product?.group_slug | uppercase }}</td>
      </ng-container>
      <!-- Product Name -->
      <ng-container matColumnDef="product_name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.product?.name }}</td>
      </ng-container>
      <!-- Status and Payment Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="badge badge-primary">{{ element.status?.name }}</span>
          </div>
          <div *ngIf="element.apud_acta_status_id">
            <span class="badge badge-info">{{ element.apud_acta_status?.name }}</span>
          </div>
        </td>
      </ng-container>
      <!-- Time in Status -->
      <ng-container matColumnDef="entered_at_status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.time-in-status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <p class="m-0">{{ returnDifferenceInDays(element.latest_case_status_log?.created_at)}}</p>
          </div>
        </td>
      </ng-container>
      <!-- Packager Status -->
      <ng-container matColumnDef="packager_status" *ngIf="!authUser.packager.master">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.packager-status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="badge badge-primary">{{ element.packager_status?.name }}</span>
          </div>
        </td>
      </ng-container>
      <!-- Created At -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.created_at" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <p class="m-0">{{ element.created_at | toDateObject | date:'dd/MM/yy' }}</p>
          <p class="m-0">{{ element.created_at | toDateObject | date:'HH:mm' }}</p>
        </td>
      </ng-container>
      <!-- Affiliate -->
      <ng-container matColumnDef="affiliate">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.affiliates" | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{ element.affiliate[0]?.name }}</td>
      </ng-container>
      <!-- Amount Paid -->
      <ng-container matColumnDef="amount_paid">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{ element.amount_paid | currency }}</td>
      </ng-container>
      <!-- Case Creditors with selected status count -->
      <ng-container matColumnDef="claims_count">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.claim_count" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <p class="m-0">{{ element?.creditors.length }}</p>
          </div>
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex" class="pl-2" *ngIf="!(!authUser.packager.master && element.packager_id === element.original_packager_id)">
            <mat-icon class="mat-icon-size pr-5" (click)="clickToCall(element.client.phone, $event)" color="primary">phone</mat-icon>
            <mat-icon class="mat-icon-size pr-5" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'edit', 'general']" color="primary">edit</mat-icon>
            <!-- " *ngIf="[1, 5, 7, 26, 27, 34, 36, 751600, 751601, 752733].includes(authUser.id) -->
            <mat-icon class="mat-icon-size pr-5" (click)="openDeleteDialog(element, $event)" color="primary">delete</mat-icon>
            <!-- <a class="pr-3" style="color: #79a303"
              (click)="clickToCall(element.client.phone, $event)">{{'CASES.single.call' | translate}}</a>
            <a class="pr-3" (click)="$event.stopPropagation()"
              [routerLink]="['/cases', element.id, 'edit', 'general']">{{ "SHARED.edit" | translate }}</a>
            <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{ "SHARED.delete" | translate }}</a> -->
          </div>
        </td>
      </ng-container>
    </table>
  
  
    <!-- Loader -->
    <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- No Data -->
    <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
      <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
    </div>
    <!-- Pagination -->
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
    </mat-paginator>
  </div>
  
  <ng-template #contactTable>
    <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id]"></tr>
      <!-- Selection -->
      <ng-container matColumnDef="select">
        <!-- Select All On Page -->
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                        [checked]="selection.hasValue() && allRowsSelected()"
                        [indeterminate]="selection.hasValue() && !allRowsSelected()">
          </mat-checkbox>
        </th>
        <!-- Select Row -->
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                        (change)="toggleRow($event, row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Ref Number -->
      <ng-container matColumnDef="ref_number">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.ref_number }} </td>
      </ng-container>
      <!-- First Name -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
      </ng-container>
      <!-- Last Name -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'USERS.model.last_name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
      </ng-container>
      <!-- Product Group Slug -->
      <ng-container matColumnDef="product_group_slug">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PRODUCT.model.group_slug" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.product?.group_slug | uppercase }}</td>
      </ng-container>
      <!-- Case Status -->
      <ng-container matColumnDef="case_status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="badge badge-primary">{{ element.status?.name }}</span>
          </div>
        </td>
      </ng-container>
      <!-- Payment Status -->
      <ng-container matColumnDef="payment_status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'CASES.single.general.status_editor.payment_status' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="badge badge-info">{{ element?.payment_status?.name }}</span>
          </div>
        </td>
      </ng-container>
      <!-- Amount Paid -->
      <ng-container matColumnDef="amount_paid">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.amount-paid" | translate }}</th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{ element.amount_paid | currency }}</td>
      </ng-container>
      <!-- Last Call Contact-->
      <ng-container matColumnDef="last_call_contact">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-call-contact" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.history_call}}</td>
      </ng-container>
      <!-- Last Successful Call Contact -->
      <ng-container matColumnDef="last_successful_call_contact">
        <th mat-header-cell mat-sort-header
            *matHeaderCellDef>{{ "CASES.single.last-successful-call-contact" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.history_successful_call}}</td>
      </ng-container>
      <!-- Agent -->
      <ng-container matColumnDef="agent">
        <th mat-header-cell *matHeaderCellDef>{{ "USERS.agent" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element?.agent}}</td>
      </ng-container>
      <!-- Call duration -->
      <ng-container matColumnDef="call_duration">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "CASES.single.general.relation_list.notification_list.call.duration" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="pr-2">{{element.call_duration}}</td>
      </ng-container>
      <!-- Last WhatsApp Contact -->
      <ng-container matColumnDef="last_whatsapp_contact">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.last-whatsapp-contact" | translate }}</th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="pl-2">{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex" class="pl-2">
            <!-- <a class="pr-3"  (click)="$event.stopPropagation()" target="_blank" style="font-weight: 400"
              [routerLink]="['/cases', element.id, 'general']">{{ "SHARED.view" | translate }}</a>
            <a class="pr-3" (click)="$event.stopPropagation()"
              [routerLink]="['/cases', element.id, 'edit', 'general']">{{ "SHARED.edit" | translate }}</a>
            <a class="pr-3" href="#" (click)="openDeleteDialog(element, $event)">{{ "SHARED.delete" | translate }}</a> -->
          </div>
        </td>
      </ng-container>
    </table>
  
  
    <!-- Loader -->
    <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- No Data -->
    <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
      <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
    </div>
    <!-- Pagination -->
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
    </mat-paginator>
  </ng-template>
  <!-- Footer Actions -->
  <div class="mt-2 pb-3 pl-1" *ngIf="authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 || isUserCreditorNegotiator">
    <!-- Export Cases -->
    <button *ngIf="filterType !== 'customer_contact' && (authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7 ||
            authUser.role_id === 26)" mat-raised-button color="primary" class="mr-3"
            [matMenuTriggerFor]="menuSecured" [disabled]="selection.selected.length === 0" >
      {{ "CASES.list.export.button-name" | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menuSecured="matMenu">
      <a mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter)">
        {{ "CASES.list.export.button-name" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="exportRecords(caseListFilter, 1)">
        {{ "CASES.list.export.button-name-marketing" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="exportClaimRecords(caseListFilter)">
        {{ "CASES.list.export.button-name-claims" | translate }}
      </a>
    </mat-menu>
    <!-- Export Customer Contact-->
    <button *ngIf="filterType === 'customer_contact' && authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7" mat-raised-button color="primary"
            (click)="exportRecordsCustomerContact(caseListFilter)" class="mr-3">
      {{ "CASES.list.export.button-name" | translate }}
    </button>
    <!-- Export Last Actions -->
    <button *ngIf="filterType !== 'legal' && filterType !== 'customer_contact' && authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7" mat-raised-button color="primary"
            (click)="exportLastActionRecords(caseListFilter)" [disabled]="selection.selected.length === 0" class="mr-3">
      {{ "CASES.list.export.button-name-last-action" | translate }}
    </button>
    <ng-container *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
      <!-- Push to Dialer -->
      <button mat-raised-button color="primary" (click)="dialerListIdSelector()"
              *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 238987 || authUser.id === 613764
              || authUser.id === 34 || authUser.id === 336521 || authUser.id === 1 || authUser.id === 110"
              [disabled]="filterType !== 'customer_contact' && selection.selected.length === 0">
        {{ "CASES.list.push_to_dialer.button" | translate }}
      </button>
      <!-- Assign Distribution -->
      <button mat-raised-button color="primary" class="ml-3" (click)="moveToDistribution(caseListFilter)"
              [disabled]="selection.selected.length === 0"  *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
        {{ "CASES.list.assign_distribution.button" | translate }}
      </button>
      <!-- Bulk Updates -->
      <button mat-raised-button color="primary" class="ml-3"
              [matMenuTriggerFor]="bulkActionsMenu" [disabled]="selection.selected.length === 0">
        {{ "CASES.list.bulk_actions.button" | translate }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #bulkActionsMenu="matMenu">
        <a mat-menu-item class="dropdown-item" (click)="openChangeModal('status', caseListFilter)" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
          {{ "CASES.list.bulk_actions.change_case_status" | translate }}
        </a>
        <a mat-menu-item class="dropdown-item" (click)="openChangeModal('department_assignments', caseListFilter)" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
          {{ "CASES.list.bulk_actions.change_department_status" | translate }}
        </a>
        <a mat-menu-item class="dropdown-item" (click)="openChangeModal('product_type', caseListFilter)" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
          {{'CASES.list.bulk_actions.change_product_type' | translate}}
        </a>
        <a mat-menu-item class="dropdown-item" (click)="downloadDocuments()" *ngIf="(authUser.role_id === 5 || authUser.role_id === 7) || isUserCreditorNegotiator">
          {{'CASES.list.bulk_actions.download_documents' | translate}}
        </a>
      </mat-menu>
    </ng-container>
  </div>