import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, map, startWith } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditorService } from '../../../../case-creditor.service';
import {Observable} from 'rxjs';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';

@Component({
  selector: 'app-gen-demand-modal',
  templateUrl: './demand.component.html',
  styles: [],
})
export class GenerateDemandComponent implements OnInit {
  public case: Case;
  public caseCreditor: CaseCreditor;
  public form: UntypedFormGroup;
  public isLoading    = 0;
  public isSubmitting = false;
  public partido_judicial = new FormControl();
  public statusFormControlName                     = 'partido_judicial';
  public filteredPartidoJudicialOptions: Observable<string[]>;
  public partidoJudicialOptions = [
    "A Coruña", "Albacete", "Alicante/Alacant", "Almería", "Araba/Álava", "Asturias", "Ávila", "Badajoz", "Barcelona", "Bizkaia", "Burgos", "Cáceres", "Cádiz", "Cantabria", "Castellón/Castelló", "Ceuta", "Ciudad Real", "Córdoba", "Cuenca", "Gipuzkoa", "Girona", "Granada", "Guadalajara", "Huelva", "Huesca", "Illes Balears", "Jaén", "La Rioja", "Las Palmas", "León", "Lleida", "Lugo", "Madrid", "Málaga", "Melilla", "Murcia", "Navarra", "Ourense", "Palencia", "Pontevedra", "Salamanca", "Santa Cruz de Tenerife", "Segovia", "Sevilla", "Soria", "Tarragona", "Teruel", "Toledo", "Valencia/Valéncia", "Valladolid", "Zamora", "Zaragoza"
  ];
  public provinceCategories = Array<any>();
  public filteredLocationCategories = Array<any>();
  public partidoJudiciales = Array<any>();
  public allLocations = Array<any>();
  public insuranceEntities = Array<any>();
  public isDisabled = false;
  constructor(private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<GenerateDemandComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private caseCreditorService: CaseCreditorService,
  ) {
    this.case = this.data.case;
    this.caseCreditor = this.data.caseCreditor;
  }

  ngOnInit(): void {
    this.filteredPartidoJudicialOptions = this.partido_judicial.valueChanges.pipe(startWith(''),
        map(value => this._filter(value))
    );
    this.buildForm();
    this.getInsuranceEntities();
    this.getPartidoJudiciales();
  }

  private buildForm(): void {
    this.form = this.fb.group({
        partido_judicial:         [null],
        part_jud_opts:            [null],
        // part_jud_opts options
        part_jud_opts_doc_num:    [null],
        // end part_jud_opts options
        forma_contratacion:       [null],
        doc_acr_rel_cont:         [null],
        forma_contratacion_other: [null],
        tipo_seguro:              [null],
        clausula_ppi_checkbox:    [null],
        clausula_cpd_checkbox:    [null],
        clausula_ca_checkbox:     [null],
        // clausule options
        clausula_ppi:             [null],
        clausula_cpd:             [null],
        clausula_ca:              [null],
        // end clausule options
        resp_recl_ej:             [null],
        // reclamacion options
        resp_recl_ej_ppi:         [null],
        // end reclamacion options
        entidades_aseguradoras:   [null],
        insurance_cif:            [null],
        no_sup_cont_inc:          [null],
        nul_otras_clausulas:      [null],
        sol_al_juzgado:           [null],
    });

    this.form.get('forma_contratacion_other').valueChanges.subscribe(value => {
        if (value) {
            this.form.get('forma_contratacion').patchValue(null);
        }
    });

    this.form.get('clausula_ppi').valueChanges.subscribe(value => {
        if (value) {
            // check clausula_ppi_checkbox
            this.form.get('clausula_ppi_checkbox').patchValue(1);
        } else {
            this.form.get('clausula_ppi_checkbox').patchValue(0);
        }
    });
    this.form.get('clausula_cpd').valueChanges.subscribe(value => {
        if (value) {
            this.form.get('clausula_cpd_checkbox').patchValue(1);
        } else {
            this.form.get('clausula_cpd_checkbox').patchValue(0);
        }
    });
    this.form.get('clausula_ca').valueChanges.subscribe(value => {
        if (value) {
            this.form.get('clausula_ca_checkbox').patchValue(1);
        } else {
            this.form.get('clausula_ca_checkbox').patchValue(0);
        }
    });
    this.form.get('entidades_aseguradoras').valueChanges.subscribe(value => {
        if (value) {
            const entity = this.insuranceEntities.find(e => e.id === value);
            let cif = entity.cif == '' ? entity.company_code : entity.cif;
            this.form.get('insurance_cif').patchValue(cif);
        }
    });

    this.form.get('tipo_seguro').valueChanges.subscribe(value => {
        if(value === 'sin_clausula') {
            this.form.get('clausula_ppi').disable();
            this.form.get('sol_al_juzgado').patchValue('sin');
            this.isDisabled = true;
        } else {
            this.form.get('clausula_ppi').enable();
            this.form.get('sol_al_juzgado').patchValue(null);
            this.isDisabled = false
        }
    });
  }

  public closeModal(value): void {
    this.dialogRef.close(value);
  }

  public submitForm(form): void {
    console.log(this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    console.log(this.caseCreditor);


    this.isSubmitting = true;
    this.caseCreditorService.generateDemandForCreditor(this.case.id, this.caseCreditor.id, this.form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        next => {
          this.toast.success(
            this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.success', {
              entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
            }));
          this.dialogRef.close(true);
        },
        error => {
          this.toast.error(
            this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
              entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
            }));
        }
      );
    }
  

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();

      return this.partidoJudicialOptions.filter(option => option.toLowerCase().includes(filterValue));

    }

    private getInsuranceEntities(): void {
        this.caseCreditorService.indexInsuranceEntities()
            .subscribe(
            next => {
                // sort entities by company name
                next.data.sort((a, b) => a.company_name.localeCompare(b.company_name));
                this.insuranceEntities = next.data;
            },
            error => {
                this.toast.error(
                this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
                    entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
                }));
            }
        );
    }

    private getPartidoJudiciales(): void {
        this.caseCreditorService.indexPartidoJudiciales()
            .subscribe(result => {
                this.provinceCategories = result.data;

                this.provinceCategories.forEach(province => {
                    this.allLocations.push(province);
                    province.partidos.forEach(partido => this.allLocations.push(partido));
                })
            },
            error => {
                this.toast.error(
                this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
                    entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
                }));
            }
        );
    }
}
