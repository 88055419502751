import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as luxon from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../distribution/distribution.service';
import { DialLeadListSelectorComponent } from '../../lead/dial-lead-list-selector/dial-lead-list-selector.component';
import { NotificationMessageComponent } from '../../notification/notification-message/notification-message.component';
import { CaseBulkActionModalComponent } from '../case-bulk-action-modal/case-bulk-action-modal.component';
import { CaseEditorPrefillComponent } from '../case-editor-prefill/case-editor-prefill.component';
import { CaseService } from '../case.service';
import { CaseListFiltersComponent } from './case-list-filters/case-list-filters.component';
import { DateTime, Duration } from 'luxon';

@Component({
  selector:    'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls:   ['./case-list.component.scss'],
})
export class CaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  // Wrapper
  @ViewChild(CaseListFiltersComponent) caseListFiltersComponent: CaseListFiltersComponent;
  public bulkActionForm: UntypedFormGroup;
  public onlyRelated: boolean;
  public filterType: 'case' | 'legal' | 'affiliate_case' | 'customer_contact' = null;
  public newPartner: 0 | 1;
  // End of wrapper

  public authUser: User;
  public isLoadingExport = 0;
  public submittingDistribution: boolean;

  public filtersReady = false;
  public caseListFilter: CaseListFilter;

  // Datatable
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoadingCases                      = 0;
  public initialSelection                    = [];
  public allowMultiSelect                    = true;
  public selection                           = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public pageSelected                        = false;
  public displayedColumns: Array<string>;
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Case>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  // End Datatable
  private initialFetchComplete: boolean;
  private subscriptions: Array<Subscription> = [];
  public isUserCreditorNegotiator            = false;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              public dialog: MatDialog,
              private translate: TranslateService,
              public globalEventsService: MainGlobalEventService,
              private caseService: CaseService,
              private distributionService: DistributionService) {
  }

  ngOnInit(): void {
    this.filterType       = this.route.snapshot.data.hasOwnProperty('filterType') ?
      this.route.snapshot.data.filterType :
      null;
    this.newPartner       = this.route.snapshot.data.hasOwnProperty('newPartner') ?
      this.route.snapshot.data.newPartner :
      null;
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    this.displayedColumns = this.authUser.packager.master ? this.getTableColumns() : this.getColumnsPackager();
  /*  if (this.authUser.packager.master || ( !this.authUser.packager.master && this.case.packager_id === this.case.original_packager_id) {
      this.displayedColumns.push('actions');
    }*/
    this.authUser.department_assignments.forEach(department_assignment => {
      if (department_assignment.department.type === 'creditor-negotiator') {
        this.isUserCreditorNegotiator = true;
      } else {
        this.isUserCreditorNegotiator = false;
      }
    });
    this.onlyRelated            = this.route.snapshot.data.hasOwnProperty('onlyRelated') ?
      this.route.snapshot.data.onlyRelated :
      null;
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.buildForms();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private buildForms(): void {
    this.bulkActionForm = this.fb.group({
      action: [{value: '', disabled: false}, Validators.required],
    });
  }

  public newCasePrefill(): void {
    this.dialog.open(CaseEditorPrefillComponent, {
      width:     '50%',
      minHeight: '230px',
    });
  }

  public clearFilters(): void {
    this.caseListFiltersComponent.clearFilters();
    this.applyFilters();
  }

  public sendNotifications(bulkActionForm: UntypedFormGroup) {
    if (bulkActionForm.invalid || !bulkActionForm.value.action || this.selection.selected?.length === 0) {
      return;
    }
    const requestData: any = this.caseListFilter;

    this.openNotificationModal(bulkActionForm.value.action, requestData);
  }

  public moveToDistribution(caseListFilter: CaseListFilter) {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    this.submittingDistribution = true;
    this.subscriptions.push(
      this.distributionService.assignToDistribution(requestFilter)
        .pipe(finalize(() => this.submittingDistribution = false))
        .subscribe(
          result => {
            this.toastr.success(this.translate.instant('CASES.list.assign_distribution.success'));
            this.clearSelection();
          },
          err => {
            console.error(err);
            this.toastr.error(this.translate.instant('CASES.list.assign_distribution.error'));
          },
        ),
    );
    this.caseListFilter.cases = null;
  }

  public exportRecords(caseListFilter: CaseListFilter, clientAllowMarketing = null): void {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'get');

    if (clientAllowMarketing !== null) {
      requestFilter.client_allow_marketing = clientAllowMarketing;
    }

    let exportObservable: Observable<any>;
    if (this.filterType === 'legal') {
      exportObservable = this.caseService.exportCasesLegal(requestFilter);
    } else if (this.filterType === 'customer_contact') {
      exportObservable = this.caseService.exportCasesCustomerContact(requestFilter);
    } else {
      exportObservable = this.caseService.exportCases(requestFilter);
    }

    this.isLoadingExport++;
    this.subscriptions.push(
      exportObservable.pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toastr.success(result.message);
          this.clearSelection();
        },
        err => console.error(err),
      ),
    );
    this.caseListFilter.cases = null;
  }

  public exportRecordsCustomerContact(caseListFilter: CaseListFilter): void {
    const oldSelectAll        = caseListFilter.select_all;
    caseListFilter.select_all = 1;
    this.exportRecords(caseListFilter);
    this.caseListFilter.select_all = oldSelectAll;
  }

  public exportClaimRecords(caseListFilter: CaseListFilter): void {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'get');

    this.isLoadingExport++;
    this.caseService.exportClaims(requestFilter).pipe(finalize(() => this.isLoadingExport--))
      .subscribe(result => {
        this.toastr.success(result.message);
        this.clearSelection();
      }, err => {
        console.error(err);
      });

    this.caseListFilter.cases = null;
  }

  public exportLastActionRecords(caseListFilter: CaseListFilter): void {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'get');

    this.isLoadingExport++;
    this.subscriptions.push(
      this.caseService.exportCasesLastAction(requestFilter).pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toastr.success(result.message);
          this.clearSelection();
        },
        err => console.error(err),
      ),
    );
    this.caseListFilter.cases = null;
  }

  public dialerListIdSelector() {
    const dialogRef = this.dialog.open(DialLeadListSelectorComponent, {
      width:     '50%',
      minHeight: '230px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.params?.list_id) {
        this.pushToDialer(this.caseListFilter, result.params.list_id, result.params.owned_by);
      }
      this.clearSelection();
    });
  }

  public pushToDialer(caseListFilter: CaseListFilter, dialListId: string, ownedBy: Array<number>) {
    const oldSelectAll   = caseListFilter.select_all;
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));

    // If no cases selected, and select_all isn't already enabled, enable select_all and disable it after the action
    if (!caseListFilter.cases.length && !caseListFilter.select_all) {
      caseListFilter.select_all = 1;
    }

    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');
    requestFilter.dialer_list_id  = dialListId;
    requestFilter.owned_by        = ownedBy;

    this.isLoadingExport++;
    this.subscriptions.push(
      this.caseService.pushToDialer(requestFilter).pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toastr.success(this.translate.instant('CASES.list.push_to_dialer.success'));
        },
        err => {
          this.toastr.error(this.translate.instant('CASES.list.push_to_dialer.error'));
        },
      ),
    );
    caseListFilter.select_all = oldSelectAll;
    this.caseListFilter.cases = null;
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleFilters($event: CaseListFilter): void {
    this.caseListFilter = $event;
    if (!this.initialFetchComplete) {
      this.applyFilters();
      this.initialFetchComplete = true;
    }
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.caseListFilter.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.caseListFilter.sort_by = this.defaultSort.active;
    this.caseListFilter.order   = this.defaultSort.direction;
  }

  private fetchCases(): void {
    this.clearSelection();  //  Reset global select
    this.caseListFilter.start_date = this.caseListFilter.start_date && this.caseListFilter.start_date instanceof Date ?
      this.caseListFilter.start_date.toISOString() :
      this.caseListFilter.start_date;
    this.caseListFilter.end_date   = this.caseListFilter.end_date && this.caseListFilter.end_date instanceof Date ?
      this.caseListFilter.end_date.toISOString() :
      this.caseListFilter.end_date;

    const requestData = MainBaseApiService.convertFiltersForRequest(this.caseListFilter, 'get');

    if (this.filterType === 'customer_contact') {
      requestData['with[0]'] = 'notification_histories.notifiable';
      requestData['with[1]'] = 'notification_histories.staff';
    }
    requestData['with[0]'] = 'packager_status';
    requestData['with[1]'] = 'apud_acta_status';
    this.dataSource = new MatTableDataSource<Case>([]);
    this.isLoadingCases++;
    this.subscriptions.push(
      this.caseService.index(requestData).pipe(finalize(() => this.isLoadingCases--)).subscribe(
        result => {
          this.clearSelection();
          this.mapLatestContactHistories(result.data, 'history_call');
          this.dataSource             = new MatTableDataSource<Case>(result.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        err => console.error(err),
      ),
    );
  }

  private mapLatestContactHistories(data, keyName) {
    data.map(obj => {
      if (obj.notification_histories?.length) {
        const notificationsReceived = obj.notification_histories.filter(notification => {
          return notification.notifiable_type === keyName;
        });

        if (notificationsReceived.length) {
          if (keyName === 'history_call') {
            const successfulCalls = this.getSuccessfulCalls(notificationsReceived);

            if (successfulCalls.length) {
              const latestSuccessfulCall  = this.getLatestNotification(successfulCalls);
              obj.history_successful_call = this.formatDateForDisplay(latestSuccessfulCall.contacted_at);
              obj.agent                   = latestSuccessfulCall?.staff?.first_name + ' ' +
                latestSuccessfulCall?.staff?.last_name;
            }
          }

          const latestNotification = this.getLatestNotification(notificationsReceived);

          if (keyName === 'history_call' && typeof latestNotification.notifiable !== 'undefined') {
            obj.call_duration = latestNotification.notifiable.call_duration;
          }
          // console.log(latestNotification);
          obj[keyName] = this.formatDateForDisplay(latestNotification.contacted_at);
          return obj;
        }
      }
    });
  }

  private getLatestNotification(data) {
    const mostRecentDate = new Date(
      Math.max(...data.map(contactHistory => {
          return new Date(contactHistory.contacted_at);
        },
      )),
    );

    // Most recent contact
    return data.filter(e => {
      const date = new Date(e.contacted_at);
      return date.getTime() === mostRecentDate.getTime();
    })[0];
  }

  private getSuccessfulCalls(data) {
    // data.filter(his => console.log(his));
    return data.filter(
      contactHistory => (typeof contactHistory.notifiable !== 'undefined' && contactHistory.notifiable.success ===
        1));
  }

  private formatDateForDisplay(date) {
    const diff  = DateTime.local().diff(DateTime.fromJSDate(date)).as('milliseconds');
    const d     = Duration.fromMillis(diff);
    const days  = `${ Math.floor(d.as('days')) } d`;
    const hours = `${ d.hours } hr${ d.hours > 1 || d.hours === 0 ? 's' : '' }`;

    return days + ', ' + hours;
  }

  // TODO: save paginate settings to localStorage
  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
  }

  public sortData(sort) {
    this.caseListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.caseListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchCases();
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    this.patchSelectAllFilter(0);
    delete this.caseListFilter.cases;
  }

  public openDeleteDialog(clientCase: Case, $event): void {
    $event.stopPropagation();
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.delete-text'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteCase(clientCase);
      }
    });
  }

  private deleteCase(clientCase: Case): void {
    this.subscriptions.push(
      this.caseService.delete(clientCase.id).subscribe(
        result => {
          const index = this.dataSource.data.indexOf(clientCase);
          this.dataSource.data.splice(index, 1);
          this.dataSource._updateChangeSubscription();
          this.toastr.success(
            this.translate.instant('CASES.delete-success'), this.translate.instant('SHARED.success'),
          );
        },
        error => this.toastr.error(error.error?.message, this.translate.instant('CASES.delete-error'))),
    );
  }

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.caseListFiltersComponent) {
      this.caseListFiltersComponent.patchFilter('select_all', state, {emitEvent: false, onlySelf: true});
      this.caseListFilter.select_all = state;
    } else {
      console.log('Filter component not ready for form pControl patching');
    }
  }

  // Note: Untested TODO: Refactor
  // ==========================================================================================================
  public toggleRow(event: MatCheckboxChange, row) {
    this.selection.toggle(row);
    if (!event.checked) {
      this.pageSelected = false;  //  Hide global select
      this.patchSelectAllFilter(0);
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(clientCase => this.selection.deselect(clientCase));
    } else {
      this.dataSource.data.forEach(clientCase => this.selection.select(clientCase));
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  private openNotificationModal(type: string, caseListFilter: CaseListFilter) {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilters          = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    const channels = [];
    if (type === 'all' || type === 'email') {
      channels.push('email');
    }
    if (type === 'all' || type === 'sms') {
      channels.push('sms');
    }

    const data      = {
      templatableType: 'case',
      channels,
      filters:         requestFilters,
    };
    const dialogRef = this.dialog.open(NotificationMessageComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clearSelection();
    });
  }

  private getTableColumns(): Array<string> {
    if (this.filterType === 'customer_contact') {
      return [
        'ref_number',
        'first_name',
        'last_name',
        'product_group_slug',
        'case_status',
        'entered_at_status',
        'payment_status',
        // 'amount_paid',
        'last_call_contact',
        'last_successful_call_contact',
        'call_duration',
        'agent',
        'last_whatsapp_contact',
        'actions',
      ];
    }  else {
      return [
        'select',
        'ref_number',
        'first_name',
        'last_name',
        'created_at',
        'affiliate',
        'status',
        // 'entered_at_status',
        // 'amount_paid',
        'claims_count',
        'actions',
      ];
    }
  }

  public getColumnsPackager(): Array<string> {
      return [
        'select',
        'ref_number',
        'first_name',
        'last_name',
        'created_at',
        'affiliate',
        'status',
        'packager_status',
        // 'entered_at_status',
        // 'amount_paid',
        'actions'
      ];
  }

  public applyFilters() {
    this.resetPagination();
    this.resetSort();
    this.fetchCases();
  }

  public openChangeModal(type: 'status' | 'department_assignments' | 'product_type', caseListFilter: CaseListFilter) {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const data                    = {
      componentType: 'case',
      formType:      type,
      requestFilter: caseListFilter,
    };
    const dialogRef               = this.dialog.open(CaseBulkActionModalComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public clickToCall(phoneNumber: number, $event) {
    $event.stopPropagation();
    this.caseService.call({phone_number: phoneNumber}).subscribe((response) => {
    });
  }

  public downloadDocuments() {
    const caseIds = this.selection.selected.map(selectedCase => selectedCase.id);
    this.caseService.bulkDocumentsDownload(caseIds).subscribe(response => {
      this.toastr.success(response.message);
      this.clearSelection();
    }, error => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public returnDifferenceInDays(statusDate: Date): number {
    const date1: any = new Date(statusDate);
    const date2: any = new Date();
    const diffDays   = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

    return diffDays;
  }
}