import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentRequest } from '../../../../../_base-shared/models/Document/AppDocumentRequest';
import { AppDocumentTypeCategory } from '../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { DocumentType } from '../../../../../_base-shared/models/DocumentType';
import { AppFile } from '../../../../../_base-shared/models/File/AppFile';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService extends MainBaseApiService {
  public indexCaseDocumentTypeCategories(caseId: number, data = {}, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<AppDocumentTypeCategory>>>(this.apiUrl + '/cases/' + caseId + '/app-document-type-categories', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getClientCaseDocumentViaType(caseId: number, userId: number, docTypeSlug: string, relations: Array<string> = []) {
    const requestData = {
      user_id:                userId,
      app_document_type_slug: docTypeSlug,
      with:                   relations
    }
    const params      = MainBaseApiService.convertFiltersForRequest(requestData, 'get');
    return this.http.get<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/cases/' + caseId + '/app-documents/get-client-doc', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public adminUploadAppDocument(caseId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/cases/' + caseId + '/app-documents/upload', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public reassignCaseCreditorDocument(caseId: number, documentId: number, data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/cases/' + caseId + '/app-documents/' + documentId, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateDocumentFile(caseId: number, fileId: number, data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse<AppFile>>(this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public deleteDocumentFile(caseId: number, fileId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId)
      .pipe(catchError(response => this.handleError(response)));
  }

  public resendRequest(caseId: number, fileId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<AppDocumentRequest>>(
      this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId + '/resend-request', data
    ).pipe(catchError(response => this.handleError(response)));
  }
  public getDemand(caseId: number, creditorId: CaseCreditor): Observable<LaravelResourceResponse<AppDocument>> {
    return this.http.get<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/cases/' + caseId + '/demand/' + creditorId.id)
      .pipe(catchError(response => this.handleError(response)));
  }

  get(): Observable<DocumentType[]> {
    const documentTypes = [
      {id: 1, name: 'dni', parentType: 'initial', type: 'personal', label: 'DNI/NIE', custom: 0},
    ];

    return of(documentTypes);
  }

  getLegalDocumentTypes(): Observable<any[]> {
    const documentTypes = [
      {id: 1, name: 'aep', type: 'extrajudicial', label: 'AEP Notary'},
    ];
    return of(documentTypes);
  }

  getCourtDocumentTypes(): Observable<any[]> {
    const documentTypes = [
      {id: 1, name: 'court-documents', type: '', label: 'Court documents'},
    ];
    return of(documentTypes);
  }
}
