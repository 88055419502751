import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { AppDocument } from '../../../../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentTypeCategory } from '../../../../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { AppFile } from '../../../../../../../../_base-shared/models/File/AppFile';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { SafePipe } from '../../../../../../../../_base-shared/pipes/safe.pipe';
import { environment } from '../../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../document/document-type.service';


@Component({
  selector:    'app-case-document-upload-list',
  templateUrl: './case-document-upload-list.component.html',
  styleUrls:   ['./case-document-upload-list.component.scss']
})
export class CaseDocumentUploadListComponent implements OnInit, OnDestroy {
  @Output() updateSignatureVerification                             = new EventEmitter<number>();
  @Input() case: Case;
  @Input() caseCreditor: CaseCreditor;
  public authUser: User;
  public caseDocumentTypeCategories: Array<AppDocumentTypeCategory> = [];
  public currentLanguage                                            = 'es';

  public formFileUpload: UntypedFormGroup;
  public selectedUploadFiles = [];
  public uploadedDocument: AppDocument;
  public formDocument: UntypedFormGroup;
  public formDocumentFile: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;

  public storageUrl = environment.STORAGE_URL + '/';

  public previewFileUrl: string;
  public previewFileType: string;
  public previewIsSticky = false;

  public imageEditingAllowed = false; // TODO: fix/enable

  // Loaders
  public isLoading         = 0;
  public filesAreUploading = false;
  public demandFile: AppFile;

  protected readonly Object = Object;

  private subscriptions: Array<Subscription> = [];

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.previewIsSticky = window.pageYOffset >= 250;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private safePipe: SafePipe,
    private globalEventsService: MainGlobalEventService,
    private documentTypeService: DocumentTypeService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.onLangChange.subscribe(next => this.currentLanguage = next.lang);

    const requestData = this.caseCreditor ? {case_creditor_id: this.caseCreditor.id} : {};
    this.isLoading++;
    this.subscriptions.push(
      this.documentTypeService.indexCaseDocumentTypeCategories(this.case.id, requestData)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.caseDocumentTypeCategories = res.data;
          this.buildForms();
        })
    )

    if (this.caseCreditor) {
      this.subscriptions.push(
        this.documentTypeService.getDemand(this.case.id, this.caseCreditor)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(res => {
            this.demandFile = res.data.files[0];
          })
      );
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public onFileSelect(event): void {
    const files      = event.target.files;
    const filesArray = [...this.selectedUploadFiles];
    if (files?.length) {
      const arr = [...files];
      arr.forEach(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    this.selectedUploadFiles = filesArray;
  }

  private buildForms() {
    this.formFileUpload = this.fb.group({
      app_document_type_id: [null, [Validators.required]],
      document_name:        [null],
      client_role:          ['client', [Validators.required]],
      files:                [[]],
    });

    this.formDocument = this.fb.group({
      case_creditor_id: [null, [Validators.required]],
    })

    this.formDocumentFile = this.fb.group({
      app_document_type_id: [null, [Validators.required]],
      case_creditor_id:     [null],
      user_id:              [null, [Validators.required]],
      name:                 [null, [Validators.required]],
    })
  }

  public submitFiles() {
    if (this.formFileUpload.invalid) {
      this.formFileUpload.markAllAsTouched();
      return;
    }
    if (this.selectedUploadFiles.length > 0) {
      this.filesAreUploading = true;
      const formData         = new FormData();
      formData.append('client_role', this.formFileUpload.get('client_role').value);
      formData.append('app_document_type_id', this.formFileUpload.get('app_document_type_id').value);

      if (this.caseCreditor) {
        formData.append('case_creditor_id', this.caseCreditor.id.toString());
      }

      this.selectedUploadFiles.forEach(file => formData.append('files[]', file));
      this.documentTypeService.adminUploadAppDocument(this.case.id, formData)
        .pipe(finalize(() => {
          this.filesAreUploading   = false;
          this.selectedUploadFiles = [];
        }))
        .subscribe(
          result => {
            this.uploadedDocument = result.data;
            this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
          },
          error => {
            this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            // TODO: show which files have failed
          });
    }
  }

  public selectPreviewFile(e: Event, documentFile: AppFile): void {
    e.preventDefault();
    let url = this.storageUrl + documentFile.path;

    if (documentFile.extension === 'pdf') {
      url = 'https://api.devuelve.es/storage/files/1248/a8a97f1c131517136425782fb05c362a.pdf';
    }

    this.previewFileUrl  = this.safePipe.transform(url + '?output=embed', 'resourceUrl') as string;
    this.previewFileType = documentFile.mime_type?.includes('image') ? 'image' : 'object';
  }

  public downloadFile(documentFile: AppFile): void {
    saveAs(this.storageUrl + documentFile.path, documentFile.name + '.' + documentFile.extension);
  }

  public toggleDocumentFileEdit(documentFile: AppFile, state: boolean, document: AppDocument): void {
    if (state) {
      this.caseDocumentTypeCategories.forEach(typeCategory => {
        typeCategory.document_types.forEach(docType => {
          docType.documents[0]?.files.map(docFile => {
            if (docFile.id !== documentFile.id) {
              docFile.editing = false;
            }
            return docFile;
          })
        })
      })
      this.formDocumentFile.patchValue({
        app_document_type_id: document.app_document_type_id,
        case_creditor_id:     document.case_creditor_id,
        user_id:              document.user_id,
        name:                 documentFile.name,
      })

    } else {
      this.formDocumentFile.patchValue({
        app_document_type_id: null,
        case_creditor_id:     null,
        user_id:              null,
        name:                 null,
      })
    }
    documentFile.editing = state;
  }

  public deleteFile(document: AppDocument, documentFile: AppFile) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        {model: 'document'}),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5'
    }).then(res => {
      if (res.isConfirmed) {
        documentFile.deleting = true;
        this.documentTypeService.deleteDocumentFile(this.case.id, documentFile.id)
          .pipe(finalize(() => documentFile.deleting = false))
          .subscribe(
            res => {
              this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
              const index = document.files.findIndex(file => file.id === documentFile.id);
              if (index >= 0) {
                document.files.splice(index, 1)
              }
            },
            err => {
              this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
            }
          );
      }
    });
  }

  public resendRequest(document: AppDocument, documentFile: AppFile) {
    this.documentTypeService.resendRequest(this.case.id, documentFile.id, {})
      .subscribe(
        res => {
          this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
        }
      );
  }

  public updateDocumentFileStatus(document: AppDocument, documentFile: AppFile, status: 'accepted' | 'declined') {
    this.documentTypeService.updateDocumentFile(this.case.id, documentFile.id, {status}).subscribe(
      res => {
        documentFile.status = res.data.status;
        this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
      },
      err => this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error')),
    );
  }

  public updateDocumentFile(documentFile: AppFile, document: AppDocument) {
    const fileData = this.formDocumentFile.value;

    if (fileData.name === '') {
      return;
    }

    if (fileData.app_document_type_id === document.app_document_type_id &&
      fileData.case_creditor_id === document.case_creditor_id &&
      fileData.user_id === document.user_id &&
      fileData.name === documentFile.name
    ) {
      documentFile.editing = false;
      this.formDocumentFile.patchValue({
        app_document_type_id: null,
        case_creditor_id:     null,
        user_id:              null,
        name:                 null,
      })

      return;
    }

    this.documentTypeService.updateDocumentFile(this.case.id, documentFile.id, fileData).subscribe(
      res => {
        documentFile.name = res.data.name;
        // TODO: if user_id or app_document_type_id has been changed, move file
        this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
      },
      err => this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error')),
    );

    documentFile.editing = false;
    this.formDocumentFile.patchValue({
      app_document_type_id: null,
      case_creditor_id:     null,
      user_id:              null,
      name:                 null,
    })
  }

  public removeFile(index: number): void {
    this.selectedUploadFiles = this.selectedUploadFiles.filter(file => file.index !== index);
  }

  // TODO: implement image editing
  public openEditModal(document: AppDocument, documentFile: AppFile) {
    //  Open dialog modal
    //const dialogRef = this.dialog.open(ImgEditModalComponent, {
    //  width: '50%',
    //  data:  {
    //    document,
    //    case: this.case,
    //  },
    //});
    ////  On close fire handle response
    //dialogRef.afterClosed().subscribe(result => {
    //  if (result) {
    //    const newTypeDoc    = this.allDocTypes.find(type => type.name === this.docInfo.value.type);
    //    const newTypeCred   = this.allCreditors.find(type => type.name === this.docInfo.value.type);
    //    const newTypePublic = this.allCreditors.find(type => type.public_organisation === this.docInfo.value.type);
    //    this.documentInfoChange$.emit({
    //      document,
    //      newTypeDoc,
    //      newTypeCred,
    //      newTypePublic,
    //      oldType:     document.type.name,
    //      name:        this.docInfo.value.name,
    //      uploaded_by: this.docInfo.value.uploaded_by,
    //    });
    //    this.toEdit = null;
    //    this.docInfo.setValue({
    //      name:        '',
    //      type:        '',
    //      uploaded_by: '',
    //    });
    //  }
    //});
  }

  public editDocument(document: AppDocument, state: boolean) {
    document.editing = state;
  }

  public reassignCaseCreditor(document: AppDocument, caseCreditorId: number) {
    this.documentTypeService.reassignCaseCreditorDocument(this.case.id, document.id, {case_creditor_id: caseCreditorId}
    ).subscribe(
      res => {
        document.case_creditor_id = caseCreditorId;
        if (this.caseCreditor && this.caseCreditor.id !== caseCreditorId) {
          document.files = [];
        }
        this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
      },
      err => this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error')),
    );
    this.formDocument.get('case_creditor_id').patchValue(null);
    this.editDocument(document, false);
  }

  public downloadDemandFile(): void {
    saveAs(this.storageUrl + this.demandFile.path, this.demandFile.name + '.' + this.demandFile.extension);
  }
}
