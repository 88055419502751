import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor, CaseCreditorPivot } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePaymentPlan } from '../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { CaseCreditorStatus } from '../../../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import {
  ChangeCreditorStatusComponent,
} from '../../../../_shared/components/change-creditor-status/change-creditor-status.component';
import { VerifySignatureComponent } from '../../../../_shared/components/verify-signature/verify-signature.component';
import { EmailPreviewComponent } from '../../../app-document/email-preview/email-preview.component';
import { CreditorService } from '../../../creditor/creditor.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';
import {
  CasePaymentPlanGeneratorComponent,
} from '../case-payment/case-payment-plan-generator/case-payment-plan-generator.component';
import { AdditionalInputComponent } from './additional-input/additional-input.component';
import { CourtFeeModalComponent } from './court-fee-modal/court-fee-modal.component';
import { CaseCreditorPaymentStatus } from '../../../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { WinAmountComponent } from './win-amount-modal/win-amount.component';
import { LegalFeeComponent } from './legal-fee-modal/legal-fee.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import {DistributionService} from '../../../distribution/distribution.service';

@Component({
  selector: 'app-case-creditor-list',
  templateUrl: './case-creditor-list.component.html',
  styles: [
    `
      .mat-icon-size {
        font-size: 25px;
        width: 25px;
        height: 25px;
      }

      .mat-form-field {
        width: 100%;
      }
    `],
})
export class CaseCreditorListComponent implements OnInit {
  public case: Case;
  public form: UntypedFormGroup;
  public claimCreditors: MatTableDataSource<CaseCreditor>;
  public displayedColumns: string[]                  = [];
  public isLoading                                   = 0;
  public isFetching                                  = 0;
  public isLoadingIds: Array<number>                 = []; //  User to determinate in which row to show spinner
  public isPublicLoading                             = 0;
  public isPublicLoadingId: number[]                 = [];
  public creditorStatuses: Array<CaseCreditorStatus> = [];
  public debtPaymentPlan: CasePaymentPlan;
  public authUser: User;

  public selectionClaim = new SelectionModel<CaseCreditor>(true, []);

  //  Enable/Disabled flags
  public isSigned = false;

  public missingAepFiles = true;
  private propuestoModelo: any;

  public claimSendAepDisabled        = true;
  public claimResendAepDisabled      = true;
  public claimVerifyDisabled         = true;
  public claimResendDisabled         = true;
  public claimRecoveryDisabled       = true;
  public claimProposalAccordDisabled = true;
  public isSubmitting                = false;
  public storageUrl                  = environment.STORAGE_URL + '/';

  public isEditing                   = false;
  public currentRow: number;
  public claimsCreditorForm: UntypedFormGroup;
  public creditorPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];

  public currentClaimValues: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private caseDocumentService: CaseDocumentService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private el: ElementRef,
    private globalEventsService: MainGlobalEventService,
    private distributionService: DistributionService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    });
    this.route.parent.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.fetchCaseCreditorStatuses();
      this.caseService.get(caseId, ['debt_payment_plan']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.isLoading++;
        this.debtPaymentPlan = result.data.debt_payment_plan;
        this.caseDocumentService.indexCaseDocuments(caseId)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(res => {
            this.case = res.data;
            this.fetchCaseCreditors({select_all: true});
            this.buildDisplayColumns();
            this.buildForm();
            this.buildClaimsForm();
            this.fetchCreditorPaymentStatuses();
            this.caseDocumentService.index(caseId, ['legal_documents'])
              .pipe(finalize(() => this.isLoading--))
              // tslint:disable-next-line:no-shadowed-variable
              .subscribe(res => {
                this.case.legal_documents = res.data.legal_documents;
                this.missingAepFiles      = !!(this.case.legal_documents.filter(
                    file => file.document_type === 'aep').length === 0 &&
                  (this.case.legal_documents.filter(
                      file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
                    this.case.legal_documents.filter(
                      file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0));
              });
            this.isLoading++;
            this.caseDocumentService.showPropuestoModelo(caseId)
              .pipe(finalize(() => this.isLoading--))
              // tslint:disable-next-line:no-shadowed-variable
              .subscribe(res => {
                this.propuestoModelo = res.data;
                if (this.propuestoModelo) {
                  this.isSigned = this.case.joint_application ?
                    !!(this.propuestoModelo.signature && this.propuestoModelo.signature_partner) :
                    !!this.propuestoModelo.signature;
                } else {
                  this.isSigned = false;
                }
              });
            this.selectionClaim.changed.subscribe(next => {
              this.checkIfDisabled(next, 'claim');
            });
            this.form.valueChanges.subscribe(value => {
              const filters = this.getFilters();
              this.fetchCaseCreditors({...filters, select_all: true});
            });
          });

      });
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, data) {
    const numSelected = selection.selected.length;
    const numRows     = data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, data) {
    if (this.isAllSelected(selection, data)) {
      selection.clear();
      return;
    }

    selection.select(...data.data);
  }

  private buildDisplayColumns() {
    this.displayedColumns = [
      'select',
      'reference',
      // 'contract_number',
      'creditor_name',
      'case_creditor_product',
      'status',
      'time_in_status',
      'extrajudicial_claim_sent_at',
      'legal_fees',
      // 'court_fees',
      // 'court_fees_status',
      // 'win_type',
      'win_amount',
      'claim_fee',
      // 'case_creditor_invoice_status',
      // 'right-of-access-answered',
      'loader',
      'claim_payment_status',
      'in_distribution',
      'actions',
    ];
  }

  buildForm() {
    this.form = this.fb.group({
      ownership: new UntypedFormControl(''),
      verified:  new UntypedFormControl(''),
      status:    [],
    });
  }

  getFilters() {
    const {ownership, verified, status} = this.form.value;
    const data                          = {};
    if (ownership) {
      data['ownership[]'] = ownership;
    }
    if (verified) {
      data['verified[]'] = verified;
    }
    if (status) {
      data['status[]'] = status;
    }
    return this.form.getRawValue();
  }

  private fetchCaseCreditors(data): void {
    this.isFetching++;
    this.claimCreditors = new MatTableDataSource<CaseCreditor>([]);
    this.caseCreditorService.indexCaseCreditors(this.case.id, [
      'status',
      'case_creditor_product',
      'creditor',
      'latest_case_creditor_status_change',
      'case_creditor_invoice_status',
      'latest_case_creditor_payment_status_change.to_status',
      'distribution'
    ], data)
      .pipe(finalize(() => this.isFetching--))
      .subscribe(value => {
        this.claimCreditors = new MatTableDataSource<CaseCreditor>(value.data);
      });
  }

  private fetchCaseCreditorStatuses(): void {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.creditorStatuses = result.data
      },
      error => this.toast.error(this.translate.instant('SHARED.error')),
    );
  }

  public clearFilters() {
    this.form.setValue({
      ownership: '',
      verified:  '',
    });
    this.fetchCaseCreditors({select_all: true});
  }

  public clearMultiSelect($event, name) {
    $event.stopPropagation();
    this.form.patchValue({[name]: []});
  }

  public verifyCreditor(caseCreditorIds: SelectionModel<CaseCreditor>, creditorType: string): void {
    if (!!this.case.client.signature_verified_at) {
      this.verifySendMandates(caseCreditorIds, creditorType);
    } else {
      this.verifySignature(this.case.client, caseCreditorIds, creditorType);
    }
  }

  public sendAep($event, caseCreditorId, creditorType) {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendAepEmails(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-aep-success'));
          ids.map(id => {
            //  Mark that AEP is sent for all creditors with same name
            //  Find creditor
            // @ts-ignore
            const item = this.claimCreditors.data.find(i => i.pivot.id === id);
            this.claimCreditors.data.map(cred => {  // Find all other creditor creditors with same id
              if (cred.id === item.id) {
                // @ts-ignore
                cred.pivot.aep_sent = 1;
              }
            });
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-aep-error'));
          }
        });

  }

  public sendProposalAndAccordSelection(selection: SelectionModel<CaseCreditorPivot>): void {
    return this.sendProposalAndAccord(selection.selected.map(cred => cred.pivot.id), selection);
  }

  public sendProposalAndAccord(caseCreditorIds: Array<number>, selection: SelectionModel<CaseCreditorPivot> = null) {
    this.isLoading++;
    this.isLoadingIds = caseCreditorIds;
    this.creditorService.sendProposalAndAcoord(this.case.id, {selected_ids: caseCreditorIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-proposal-accord-success'));
          if (selection.selected) {
            selection.clear();
          }
        },
        error => {
          if (selection.selected) {
            selection.clear();
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-proposal-accord-error'));
          }
        });
  }

  public sendRightOfAccessNotice(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccessNotice(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          const emailFailedCaseCreditors  = value?.errors?.case_creditors;
          const emailSuccessCaseCreditors = this.claimCreditors.filteredData.filter(claimCreditor => {
            return !emailFailedCaseCreditors.find(
              emailFailedCaseCreditor => (emailFailedCaseCreditor.reference_number === claimCreditor.reference_number),
            );
          }).filter(emailSuccessCreditor => selectedIds.includes(emailSuccessCreditor.id));

          if (emailSuccessCaseCreditors) {
            emailSuccessCaseCreditors.forEach(caseCreditor => {
              this.toast.success(
                this.translate.instant('SHARED.submit_result.sent_email.success',
                  {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')},
                ),
                caseCreditor.reference_number,
              );
            });
          }

          if (emailFailedCaseCreditors) {
            emailFailedCaseCreditors.forEach(caseCreditorError => {
              this.toast.error(caseCreditorError.errors[0], caseCreditorError.reference_number,
                {disableTimeOut: true});
            });
          }
        },
        error => {
          if (error.error?.errors && error.error?.errors.length) {
            this.throwMultipleErrors(error.error?.errors, 'right-of-access');
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
          }
        });
  }

  public sendExtrajudicialClaim(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;
    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendExtrajudicialClaim(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.success'));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors, 'send-extrajudicial-claim');
          } else {
            this.toast.error(this.translate.instant('SHARED.error'));
          }
        });
  }

  public sendRightOfAccessPpi(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccessPpi(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        },
        error => {
          this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        });
  }

  public sendPpiClaim(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendPpiClaim(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
          }
        });
  }

  public sendAzcarateLoanLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateLoanLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
          }
        });
  }

  public sendAzcarateRevolvingLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateRevolvingLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
          }
        });
  }

  private markAsVerified(caseCreditorId: number): void {
    const caseCreditor    = this.claimCreditors.data.find(i => i.id === caseCreditorId);
    caseCreditor.verified = 1;
  }

  private verifySendMandates(caseCreditorId: SelectionModel<CaseCreditor>, creditorType: string) {
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.id) : [];
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.verifySendMandates(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.forEach(message => {
            this.toast.success(message.message);
          });

          ids.forEach(id => {
            this.markAsVerified(id);
            // this.updateStatus(id, creditorType, 'mandate_sent'); // TODO: @x - move case creditor status update to backend?
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors, 'send-mandates');
          } else {
            this.toast.error(this.translate.instant('CASES.details.resend-mandates-error'));
          }
        });
  }

  private throwMultipleErrors(errorsArray: Array<string> | Array<{errors: string, id: number, name: string, reference_number: string }>,
                              type: 'send-mandates' | 'right-of-access' | 'send-extrajudicial-claim'): void {
    errorsArray.forEach(caseCreditorError => {
      if (type === 'right-of-access') {
        this.toast.error(caseCreditorError.errors[0], caseCreditorError.reference_number,
          {disableTimeOut: true});
      } else {
        this.toast.error(caseCreditorError, '',
          {disableTimeOut: true});
      }
    });
  }

  public verifySignature(client: User, caseCreditorId: SelectionModel<CaseCreditor>, creditorType): void {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        user: client,
        verifyCreditor: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data?.result) {
        if (creditorType === 'public_debts') {
          this.additionalInput(caseCreditorId, creditorType);
        } else {
          this.verifySendMandates(caseCreditorId, creditorType);
        }
      }
    });
  }

  private additionalInput(debtIds: SelectionModel<CaseCreditor>, creditorType) {
    const ids              = debtIds.selected ? debtIds.selected.map(cred => cred.id) : [];
    this.isPublicLoading   = 1;
    this.isPublicLoadingId = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        debtIds,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          ids.forEach(id => {
            this.markAsVerified(id);
          });
          this.isPublicLoading   = 0;
          this.isPublicLoadingId = [];
          if (debtIds.selected) {
            debtIds.clear(); //  Remove selections
          }
        }
      } else {
        this.isPublicLoading   = 0;
        this.isPublicLoadingId = [];
        if (debtIds.selected) {
          debtIds.clear(); //  Remove selections
        }
      }
    });
  }

  public openPaymentPlanGenerationModal(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;
    const dialogRef   = this.dialog.open(CasePaymentPlanGeneratorComponent, {
      width: '60%',
      data: {
        case: this.case,
        debtPaymentPlan: this.debtPaymentPlan,
        caseCreditorsIds: selectedIds,
        preselectedPlanType: 'claim_fee',
        isBulk:              true,
        // paymentPlanFilter: {only_additional_fee: 1}
      },
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  public openCourtFeesModal(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    const dialogRef = this.dialog.open(CourtFeeModalComponent, {
      width: '60%',
      data: {
        caseCreditors: selectedIds,
        case: this.case
      }
    });
  }

  public selectAsDDA(selectedCaseCreditors): void {
    console.log('chosse as dda');
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;
    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.selectAsDDA(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.success'));
        },
        error => {
          this.toast.error(this.translate.instant('SHARED.error'));
        });
  }

  sendDeleteNumber($event: MouseEvent, ids: any[]) {
    $event.preventDefault();

    this.isLoading++;
    this.isLoadingIds = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width: '50%',
      height: '50%',
      data:   {
        type:       'number',
        case:       this.case,
        creditorId: ids[0],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading    = 0;
      this.isLoadingIds = [];
    });
  }

  sendAntiHarassment($event: MouseEvent, ids: any[]) {
    $event.preventDefault();
    this.isLoading++;
    this.isLoadingIds = ids;

    this.creditorService.sendAntiHarassmentEmail(ids[0])
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  sendLoanCancellationEmail($event: MouseEvent, ids: any[]) {
    $event.preventDefault();
    this.isLoading++;
    this.isLoadingIds = ids;

    this.creditorService.sendLoanCancellationEmail(ids[0])
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public getRightOfAccess(clientRole: 'client' | 'partner', creditorId: number, $event) {
    $event.preventDefault();
    this.caseDocumentService.generateRightOfAccessLetter(clientRole, creditorId)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'right_of_access_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded right of access letter');
        }
      }, err => {
        this.toastr.error('Failed to generate Demanda document');
      });
  }

  changeStatus($event: MouseEvent, selection, type: string) {
    $event.preventDefault();
    const ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;

    const dialogRef = this.dialog.open(ChangeCreditorStatusComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        ids,
        type,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (selection.selected) {
        selection.clear(); //  Remove selections
      }
      if (result) {
        ids.map(id => {
          // this.updateStatus(id, type, result);
        });
      }
    });
  }

  private checkIfDisabled(selection, type: string): void {
    if (type === 'claim') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.claimVerifyDisabled   = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.creditor.verified === 1).length);
      //  Used to Disable/Enable `send mandate recovery` action
      this.claimRecoveryDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.creditor.recovery_creditor_id).length);
      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.claimResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.creditor.verified !== 1).length);
      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.claimSendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.claimResendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.claimProposalAccordDisabled = !!(selection.source.selected.length === 0 || !this.isSigned ||
        selection.source.selected.filter(cred => !cred.pivot.proposal_location).length);
    }
  }

  sendLastChance($event: MouseEvent, selection) {
    $event.preventDefault();
    const ids = selection.selected ? selection.selected.map(cred => cred.pivot.id) : selection;
    Swal.fire({
      title: this.translate.instant('SHARED.warning'),
      text: this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = ids;

        this.creditorService.sendLastChanceEmail(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = [];
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
              if (selection.selected) {
                selection.clear(); //  Remove selections
              }
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  sendEmail($event: MouseEvent, id) {
    $event.preventDefault();

    Swal.fire({
      title: this.translate.instant('SHARED.warning'),
      text: this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = id;

        this.creditorService.sendAzcarateEmail(id)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = null;
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  public previewEmail(emailType: 'SettlementDemand' | 'ExtrajudicialNotice', caseId: number, creditorId: number) {
    this.caseCreditorService.previewEmail(caseId, creditorId, emailType).subscribe(result => {
        const emailHeader = {
          from:    result.data.from,
          to:      result.data.to,
          cc:      result.data.cc,
          subject: result.data.subject,
        };

        const dialogRef = this.dialog.open(EmailPreviewComponent, {
          width: '310mm',
          height: '100vh',
          data: {
            title: 'Creditor Document Request Preview',
            previewType: 'email',
            emailHeader,
            emailBody:   result.data.body,
            attachments: result.data.attachments,
          },
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res && res.action === 'send-email') {
            this.caseCreditorService.sendEmail(caseId, creditorId, emailType).subscribe(
              r => this.toast.success('Sent ' + emailType),
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
          if (res && res.action === 'save-and-download-document' && res.params?.type) {
            this.caseCreditorService.saveAndDownloadDocument(caseId, creditorId, res.params.type).subscribe(
              r => {
                if (r.type === 4) {
                  const fileName = res.params?.type + '_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.pdf';
                  saveAs(r.body, fileName);
                  this.toast.success('Document downloaded');
                }
              },
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
        });
      },
      errorResponse => this.parseValidationErrors(errorResponse));
  }

  private parseValidationErrors(errorResponse: any) {
    const error = errorResponse.error;
    if (+error.code === 422 && error.errors) {
      for (const [key, value] of Object.entries(error.errors)) {
        const valueArray = value as any;
        valueArray.forEach(e => this.toast.error(e));
      }
    }
  }

  public returnLenderType(lenderType: 'standard' | 'high_interest' | 'collections') {
    const types = {
      collections:   '',
      standard:      '',
      high_interest: '#FFFF63',
    };
    return types[lenderType];
  }

  public returnDifferenceInDays(statusDate: Date): number {
    const date1: any = new Date(statusDate);
    const date2: any = new Date();
    const diffDays   = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  public toggleLoanEditMode(index: number, element): void {
    this.currentRow = index;
    this.isEditing = !this.isEditing;

    if(this.isEditing) {
      const buttons = document.querySelectorAll('.edit-button');
      buttons.forEach(button => {
        if (button.id !== index.toString()) {
          button.setAttribute('disabled', 'true');
        }
      });

      this.currentClaimValues = element

      // this.claimsCreditorForm.get('contract_number').setValue(element.contract_number);
      this.claimsCreditorForm.get('case_creditor_payment_status_id').setValue(element.latest_case_creditor_payment_status_change?.to_status?.id);
      this.claimsCreditorForm.get('case_creditor_status_id').setValue(element.status?.id);
      this.claimsCreditorForm.get('extrajudicial_claim_sent_at').setValue(element.extrajudicial_claim_sent_at);
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }

    if(!this.isEditing) {
      const formData = this.claimsCreditorForm.getRawValue();
      formData['payment_status_id'] = +formData['case_creditor_payment_status_id'];
      formData['status_id'] = +formData['case_creditor_status_id'];
      // formData['contract_number'] = formData['contract_number'];
      this.saveLoanCreditorData(element, formData);
    }
  }

  public buildClaimsForm(): void {
    this.claimsCreditorForm = this.fb.group({
      // contract_number:                 new UntypedFormControl(''),
      case_creditor_payment_status_id: new UntypedFormControl(''),
      case_creditor_status_id:         new UntypedFormControl(''),
      extrajudicial_claim_sent_at:     new UntypedFormControl(''),
    });
    this.claimsCreditorForm.get('case_creditor_status_id').valueChanges.subscribe(value => {
        if(value === 11) {
            this.claimsCreditorForm.get('case_creditor_payment_status_id').setValue(10)
        } else {
            this.claimsCreditorForm.get('case_creditor_payment_status_id').setValue(this.currentClaimValues.latest_case_creditor_payment_status_change?.to_status?.id)
        }
    });
  }

  private fetchCreditorPaymentStatuses(): void {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorPaymentStatuses({select_all: 1}, [], ['name'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.creditorPaymentStatuses = result.data,
        error => this.toastr.error(this.translate.instant('SHARED.error')),
      );
  }

  public saveLoanCreditorData(element, formValue): void {
    this.isLoading++;
    this.isLoadingIds = element.id;

    this.caseCreditorService.updateCaseCreditor(this.case.id, element.id, formValue)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.success'));
          this.isEditing = false;
          this.fetchCaseCreditors({select_all: true});
          this.currentRow = null;
        },
        error => {
          this.toast.error(this.translate.instant('SHARED.error'));
        });
  }

  public sumLegalFees(creditor): string {
    let legal_fee = creditor.legal_fees.reduce((a, b) => a + b.amount, 0);
    if (legal_fee === 0) {
      return null;
    }
    return legal_fee;
  }

  public sumClaimFees(creditor): number {
    let claim_fee = creditor.claim_fees.reduce((a, b) => a + b.amount, 0);
    if (claim_fee === 0) {
      return null;
    }
    return claim_fee;
  }

  public addWinFee() {
    const dialogRef = this.dialog.open(WinAmountComponent, {
      width: '50%',
      height: 'fit-content',
      data: {
        case: this.case,
        debt: this.debtPaymentPlan,
        creditors: this.claimCreditors.data,
      },
    });
  }

  public addLegalFee() {
    const dialogRef = this.dialog.open(LegalFeeComponent, {
      width: '50%',
      height: 'fit-content',
      data: {
        case: this.case,
        debt: this.debtPaymentPlan,
        creditors: this.claimCreditors.data,
      },
    });
  }

  public sendBuroFaxToClient($event: MouseEvent, selection) {
    $event.preventDefault();
    this.isLoading++;
    const ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;

    this.caseCreditorService.sendBuroFaxToClient(this.case.id, ids)
      .pipe(finalize(() => {
        this.isLoading--;
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public sendToDistribution($event: MouseEvent, selection: SelectionModel<any>): void {
    $event.preventDefault();
    this.isLoading++;
    const claimIds = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    const distributionRequest = {claim_ids: claimIds};

    this.distributionService.assignClaimToDistribution(distributionRequest)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.toast.success(this.translate.instant('CASE_CREDITOR.model.distribution.assign_distribution.success'));
          selection.clear();
          },
        error => {
          this.toast.error(this.translate.instant('CASE_CREDITOR.model.distribution.assign_distribution.error'));
          }
      );
  }
}
