<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{ "CASES.single.general.status_editor.heading" | translate }}</h3>
      </div>
      <div *ngIf="!isEditing" class="col-5 text-right">
        <button mat-icon-button color="primary" (click)="switchEdit($event)" class=" p-0">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body d-flex flex-column">
    <div *ngIf="isLoading" class="pt-4 pl-4">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>

    <app-server-response [response]="serverResponse"></app-server-response>
    <form [formGroup]="form" [hidden]="isLoading" class="disabled-form" (ngSubmit)="submitForm(form)">
      <!-- Super status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
                   appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                   [label]="'CASES.single.general.status_editor.status_category' | translate"
                   [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                   (ngModelChange)="updateSelectedStatusCategories($event)"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Sub status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input *ngIf="filteredStatusCategories?.length" type="select" [formControlName]="statusFormControlName"
                   appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                   [label]="'CASES.single.general.status_editor.status' | translate"
                   [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                   [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Apud Acta status -->
       <div class="row align-items-baseline" style="padding-left: 12px">
         <app-input type="select" formControlName="apud_acta_status_id" appearance="standard" [searchable]="false"
                    [label]="'CASES.single.general.status_editor.apud_acta' | translate"
                    [selectOptions]="apudActaStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                    [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
         </app-input>
       </div>
      <!-- Payment status -->
       <div class="row align-items-baseline" style="padding-left: 12px">
         <app-input type="select" formControlName="payment_status_id" appearance="standard" [searchable]="true"
                    [label]="'CASES.single.general.status_editor.payment_status' | translate"
                    [selectOptions]="paymentStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                    [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
         </app-input>
       </div>
      <!-- Call status -->
      <!-- <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="call_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.call_status' | translate"
                   [selectOptions]="callStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div> -->
      <!-- Packager status -->
      <!-- <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="packager_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.packager_status' | translate"
                   [selectOptions]="packagerStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div> -->
      <!-- Case Distribution Batch -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          {{ 'DISTRIBUTION.cases.list.table.distribution_batch_name' | translate }}
        </mat-label>
        <div class="col-6 text-right">
          <p *ngIf="!case.distribution" class="m-0" style="padding-top: 7px;">
            {{ case.distribution_nonviable ? ('CASES.model.distribution_nonviable' | translate) : 'N/A' }}
          </p>
          <p *ngIf="case.distribution" class="m-0" style="padding-top: 7px;">
            {{ 'CASES.single.general.status_editor.distribution.in_distribution' | translate }}
            <span *ngIf="case.distribution" style="padding-top: 7px;">
            , {{ 'CASES.single.general.status_editor.distribution.in_batch' | translate }}:
            <span *ngIf="case.distribution?.batch">{{ 'SHARED.yes' | translate }}</span>
            <span *ngIf="!case.distribution?.batch">{{ 'SHARED.no' | translate }}</span>
          </span>
          </p>
        </div>
      </div>
      <!-- Distribution Batch Funded Warning -->
      <div *ngIf="case.distribution?.batch?.status === 'funded'" class="row font-weight-bold p-3"
           style="font-size:20px; color: red">
        <p>{{ 'CASES.single.general.status_editor.batch_funded_warning' | translate }}</p>
      </div>
      <div *ngIf="isEditing" class="mt-auto">
        <div class="row pt-3">
          <div class="col-12 text-right">
            <button mat-button color="primary" class="mr-3" (click)="switchEdit($event)">
              {{ 'SHARED.cancel' | translate }}
            </button>
            <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
              {{ 'SHARED.save' | translate }}
            </app-spinner-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
