<div class="d-flex flex-column h-100">
    <form *ngIf="form" [formGroup]="form" class="p-2">
        <h1>Demand Generator</h1>
        <div class="row">
            <div class="col-4">
                <h4>Partido Judicial</h4>
            </div>
            <div class="col-8">
                <div>
                    <app-input *ngIf="provinceCategories?.length" type="select"
                               [formControlName]="statusFormControlName"
                               appearance="standard" [searchable]="true" [multiple]="false"
                               label="Partido Judicial" [optGroupProperty]="'partidos'"
                               [selectOptions]="provinceCategories" [optGroupLabel]="'province'"
                               [selectLabel]="'location'" [selectValue]="'id'" class="full-width"
                               [showClear]="false" [fullWidth]="true">
                    </app-input>
                    <mat-radio-group formControlName="part_jud_opts" aria-label="Select an option">
                        <mat-radio-button class="col-12" value="acredito mediante poder para pleitos que aportamos como Documento nº">acredito mediante poder para pleitos que aportamos como Documento nº <input type="text" formControlName="part_jud_opts_doc_num"></mat-radio-button>
                        <mat-radio-button class="col-12" value="se acreditará mediante apud acta presencial cuando se nos requiera por el Juzgado">se acreditará mediante apud acta presencial cuando se nos requiera por el Juzgado</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Forma de contratación</h4>
            </div>
            <div class="col-8">
                <div>   
                    <mat-radio-group formControlName="forma_contratacion" aria-label="Select an option">
                        <mat-radio-button class="col-4" value="de manera telemática">de manera telemática</mat-radio-button>
                        <mat-radio-button class="col-4" value="de manera telefónica">de manera telefónica</mat-radio-button>
                        <mat-radio-button class="col-4" value="en el establecimiento financiero">en el establecimiento financiero</mat-radio-button>
                        <mat-radio-button class="col-4" value="en el establecimiento comercial">en el establecimiento comercial</mat-radio-button>
                        <mat-radio-button class="col-4" value="en un concesionario">en un concesionario</mat-radio-button>
                    </mat-radio-group>
                    <app-input class="col-2" type="text" formControlName="forma_contratacion_other" [extraLabel]="false" [fullWidth]="true" label="Other">
                    </app-input>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Documento acreditativo de relación contractual</h4>
            </div>
            <div class="col-8">
                <div>   
                    <mat-radio-group formControlName="doc_acr_rel_cont" aria-label="Select an option">
                        <mat-radio-button class="col-4" value="Con recibo de pago">Con recibo de pago</mat-radio-button>
                        <mat-radio-button class="col-4" value="Sin recibo de pago">Sin recibo de pago</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Tipo de seguro</h4>
            </div>
            <div class="col-8">
                <div>   
                    <mat-radio-group formControlName="tipo_seguro" aria-label="Select an option">
                        <mat-radio-button class="col-4" value="con_prima_unica">Con prima única financiada</mat-radio-button>
                        <mat-radio-button class="col-4" value="con_prima_mensual">Con prima mensual</mat-radio-button>
                        <mat-radio-button class="col-4" value="sin_clausula">Sin cláusula</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Nombre de las cláusulas</h4>
            </div>
            <div class="col-8">
                <div style="padding-left: 13px !important;">
                    <div class="row">
                        <div class="col-8"><mat-checkbox formControlName="clausula_ppi_checkbox" [disabled]="isDisabled">Cláusula PPI</mat-checkbox></div>
                        <div class="col-4">→ <input type="text" formControlName="clausula_ppi"></div>
                    </div>
                    <div class="row">
                        <div class="col-8"><mat-checkbox formControlName="clausula_cpd_checkbox">Cláusula CPD</mat-checkbox></div>
                        <div class="col-4">→ <input type="text" formControlName="clausula_cpd"></div>
                    </div>
                    <div class="row">
                        <div class="col-8"><mat-checkbox formControlName="clausula_ca_checkbox">Cláusula CA</mat-checkbox></div>
                        <div class="col-4">→ <input type="text" formControlName="clausula_ca"></div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Respuesta a la reclamación extrajudicial</h4>
            </div>
            <div class="col-8">
                <div>   
                    <mat-radio-group formControlName="resp_recl_ej" aria-label="Select an option">
                        <mat-radio-button class="col-6" value="con_respuesta">Con respuesta: → <input type="date" formControlName="resp_recl_ej_ppi"></mat-radio-button>
                        <mat-radio-button class="col-6" value="sin_respuesta">Sin respuesta a la reclamación extrajudicial</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Entidades aseguradoras </h4>
            </div>
            <div class="col-8">
                <div>
                    <div class="row">
                        <div class="col-8">
                            <app-input type="select" formControlName="entidades_aseguradoras" [fullWidth]="true"
                                selectLabel="company_name" selectValue="id"
                                label="Entidades Aseguradora"
                                [selectOptions]="insuranceEntities" [showClear]="false" [searchable]="true">
                            </app-input>
                        </div>
                        <div class="col-4">
                            <app-input type="text" formControlName="insurance_cif" [extraLabel]="false" [fullWidth]="true"
                                [showClear]="false" label="CIF">
                            </app-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>No superación de los controles de incorporación</h4>
            </div>
            <div class="col-8">
                <div>
                    <mat-radio-group formControlName="no_sup_cont_inc" aria-label="Select an option">
                        <mat-radio-button class="col-6" value="con">Con cláusula PPI</mat-radio-button>
                        <mat-radio-button class="col-6" value="sin">Sin cláusula PPI</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Nulidad de otras cláusulas</h4>
            </div>
            <div class="col-8">
                <div>
                    <mat-radio-group formControlName="nul_otras_clausulas" aria-label="Select an option">
                        <mat-radio-button class="col-6" value="com_de_apertura">B. CLÁUSULA DE COMISIÓN DE APERTURA (opcional)</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-4">
                <h4>Solicito al Juzgado</h4>
            </div>
            <div class="col-8">
                <div>
                    <mat-radio-group formControlName="sol_al_juzgado" aria-label="Select an option">
                        <mat-radio-button class="col-6" value="con" [disabled]="isDisabled">Con cláusula</mat-radio-button>
                        <mat-radio-button class="col-6" value="sin">Sin cláusula</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">
        <button type="button" mat-stroked-button color="primary" class="mr-3"
                (click)="closeModal('')" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
        <button *ngIf="!isSubmitting" mat-raised-button color="primary" (click)="submitForm(form)">
            {{"SHARED.select" | translate}}
        </button>
        <mat-spinner *ngIf="isSubmitting" diameter="20" color="accent"></mat-spinner>
        </div>
    </form>
</div>