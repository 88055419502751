import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import * as luxon from 'luxon';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import {Case} from '../../../../../../../../_base-shared/models/Case/Case';
import {Product} from '../../../../../../../../_base-shared/models/Product';
import {PackagerStatus} from '../../../../../../../../_base-shared/models/Status/PackagerStatus';
import {PaymentStatus} from '../../../../../../../../_base-shared/models/Status/PaymentStatus';
import {Status} from '../../../../../../../../_base-shared/models/Status/Status';
import {TaskTemplate} from '../../../../../../../../_base-shared/models/Task/TaskTemplate';
import {User} from '../../../../../../../../_base-shared/models/User/User';
import {MainGlobalEventService} from '../../../../../_shared/services/main-global-event.service';
import {PackagerStatusService} from '../../../../admin-packager/packager-status/packager-status.service';
import {PaymentStatusService} from '../../../../payment-status/payment-status.service';
import {StatusPickerTrait} from '../../../../status/status-picker.trait';
import {StatusService} from '../../../../status/status.service';
import {TaskEditorComponent} from '../../../../task/task-editor/task-editor.component';
import {CaseService} from '../../../case.service';
import {ProductService} from '../../../product.service';
import {AppointCourtModalComponent} from '../../case-draft/appoint-court-modal/appoint-court-modal.component';
import {CallStatus} from '../../../../../../../../_base-shared/models/Status/CallStatus';
import {CallStatusService} from '../../../../call-status/call-status.service';

@Component({
  selector: 'app-case-status-editor',
  templateUrl: './case-status-editor.component.html',
  styles: [],
})
export class CaseStatusEditorComponent extends StatusPickerTrait implements OnInit, OnDestroy {
  @Input() case: Case;
  @Output() caseUpdate: EventEmitter<Case> = new EventEmitter<Case>();
  public serverResponse: LaravelResourceResponse;
  public isLoading = 0;
  public isSubmitting: boolean;
  public isEditing = false;
  public form: UntypedFormGroup;

  public statusFormControlName = 'status_id';
  public products: Array<Product> = [];
  public paymentStatuses: Array<PaymentStatus> = [];
  public callStatuses: Array<CallStatus> = [];
  public packagerStatuses: Array<PackagerStatus> = [];
  private taskTemplateWatchers: Array<TaskTemplate> = [];
  private authUser: User;
  public apudActaStatuses: Array<Status> = [];
//   public apudActaStatuses: { name: string; value: number; }[];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private productService: ProductService,
    private caseService: CaseService,
    private statusService: StatusService,
    private paymentStatusService: PaymentStatusService,
    private callStatusService: CallStatusService,
    private packagerStatusService: PackagerStatusService
  ) {
    super('status_id', false, false);
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchProducts();
    this.fetchStatuses();
    this.fetchPaymentStatus();
    this.fetchTaskTemplateWatchers(this.case);
    this.fetchCallStatuses();
    this.fetchPackagerStatuses();
    this.loadCaseRelations(this.case);
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public switchEdit($event = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.isEditing = !this.isEditing;
    this.enableDisableStatusForm(this.isEditing);
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    let caseWasUpdated = false;
    this.isSubmitting = true;
    this.caseService.changeStatus(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.switchEdit();
        this.toastr.success(this.translate.instant('CASES.single.general.status_editor.result.status_updated'));
        const oldCase = {...this.case};
        const updatedCase = result.data;

        if (oldCase.status_id !== updatedCase.status_id) {
          this.case.status_id = updatedCase.status_id;
          this.case.status = this.allStatuses.find(
            fStatus => fStatus.status_id && (fStatus.id === updatedCase.status_id),
          );
          caseWasUpdated = true;
        }
        if (oldCase.payment_status_id !== updatedCase.payment_status_id) {
          this.case.payment_status_id = updatedCase.payment_status_id;
          this.case.payment_status = this.paymentStatuses.find(
            findPaymentStatus => findPaymentStatus.id === updatedCase.payment_status_id,
          );
          caseWasUpdated = true;
        }
        if (oldCase.call_status_id !== updatedCase.call_status_id) {
          this.case.call_status_id = updatedCase.call_status_id;
          caseWasUpdated = true;
        }
        if (oldCase.packager_status_id !== updatedCase.packager_status_id) {
          this.case.packager_status_id = updatedCase.packager_status_id;
          caseWasUpdated = true;
        }
        if (oldCase.apud_acta_status_id !== updatedCase.apud_acta_status_id) {
          this.case.apud_acta_status_id = updatedCase.apud_acta_status_id;
          caseWasUpdated = true;
        }
        if (caseWasUpdated) {
          this.caseUpdate.emit(this.case);
          this.checkStatusChangeWatchers(oldCase, this.case);
          this.checkTaskTemplateWatchers(oldCase, this.case);
        }
      },
      err => {
        this.serverResponse = err.error;
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      },
    );
  }

  public updateSelectedStatusCategories(selectedStatusCategoryIds: Array<number> | number) {
    this.filteredStatusCategories = [];
    setTimeout(() => super.updateSelectedStatusCategories(selectedStatusCategoryIds), 0);
  }

  private checkStatusChangeWatchers(oldCase: Case, updatedCase: Case) {
    const watchedStatuses = ['Demanda repartida', 'Demanda admitida', 'DDA Admitida + 15 EPI'];
    const watchedPaymentStatuses = [];
    if (oldCase.status_id !== updatedCase.status_id && updatedCase.status &&
      watchedStatuses.includes(updatedCase.status.name)
    ) {
      this.openCourtModal();
    }

    if (oldCase.payment_status_id !== updatedCase.payment_status_id && updatedCase.payment_status &&
      watchedPaymentStatuses.includes(updatedCase.payment_status.name)
    ) {
      this.openCourtModal();
    }
  }

  private checkTaskTemplateWatchers(clientCase: Case, updatedCase: Case) {
    this.taskTemplateWatchers.forEach(taskTemplate => {
      if (clientCase.status_id !== updatedCase.status_id &&
        taskTemplate.statusable_type === 'status' && taskTemplate.statusable_id === updatedCase.status_id) {
        // Status changed and template is observing the new status
        this.addTask(updatedCase, taskTemplate, updatedCase.status, !!taskTemplate.force_task_creation);
      }

      if (clientCase.payment_status_id !== updatedCase.payment_status_id &&
        taskTemplate.statusable_type === 'payment_status' &&
        taskTemplate.statusable_id === updatedCase.payment_status_id) {
        // Payment Status changed and template is observing the new status
        this.addTask(updatedCase, taskTemplate, updatedCase.payment_status, !!taskTemplate.force_task_creation);
      }
    });
  }

  private addTask(clientCase: Case, taskTemplate: TaskTemplate, status: Status | PaymentStatus, force = false) {
    this.toastr.info(clientCase.ref_number + ' requires a task for ' + status.name);
    this.dialog.open(TaskEditorComponent, {
      width: '40%',
      autoFocus: true,
      closeOnNavigation: !force,
      disableClose: force,
      data: {
        editorType: 'create',
        case: clientCase,
        taskTemplate,
        prefill: true,
        quickTask: true,
      },
    });
  }

  private enableDisableStatusForm(isEnabled: boolean) {
    if (isEnabled) {
      this.statusCategoryControl.enable();
      this.form.get(this.statusFormControlName).enable();
      this.statusControl.enable();
      this.form.get('payment_status_id').enable();
      this.form.get('apud_acta_status_id').enable();
      if (this.authUser.role_id === 5 || [3, 6, 967, 2497, 2595, 130100].includes(this.authUser.id)) {
        this.form.get('call_status_id').enable();
      }
      if (this.authUser?.packager?.master) {
        this.form.get('packager_status_id').enable();
      }
    } else {
      this.statusCategoryControl.disable();
      this.form.get(this.statusFormControlName).disable();
      this.statusControl.disable();
      this.form.get('apud_acta_status_id').disable();
      this.form.get('payment_status_id').disable();
      this.form.get('call_status_id').disable();
      this.form.get('packager_status_id').disable();
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      [this.statusFormControlName]: [{value: this.case.status_id, disabled: true}, [Validators.required]],
      apud_acta_status_id: [{value: this.case.apud_acta_status_id, disabled: true}],
      payment_status_id: [{value: this.case.payment_status_id, disabled: true}],
      call_status_id: [{value: this.case.call_status_id, disabled: true}],
      packager_status_id: [{value: this.case.packager_status_id, disabled: true}]
    });
    this.statusControl.disable();
    this.statusCategoryControl.disable();
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data,
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.statusCategories = result.data;
          this.statusCategories.find(category => category.id === 428).statuses.forEach(status => {
            this.apudActaStatuses.push(status);
          });
          console.log(this.apudActaStatuses);
          this.statusCategories         = this.statusCategories.filter(category => category.id !== 428);
          this.filteredStatusCategories = result.data;
          this.statusCategories.forEach(category => {
            this.allStatuses.push(category);
            category.statuses.forEach(status => this.allStatuses.push(status));
          });
          this.setStatusControls(this.form.get(this.statusFormControlName).value);
        },
      ),
    );
  }

  private fetchPaymentStatus() {
    this.isLoading++;
    this.paymentStatusService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchTaskTemplateWatchers(clientCase: Case) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.indexTaskTemplateWatchers(clientCase.id).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.taskTemplateWatchers = result.data),
    );
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.callStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.callStatuses = result.data)
    );
  }

  private fetchPackagerStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.packagerStatuses = result.data)
    );
  }

  private loadCaseRelations(clientCase: Case) {
    const relations = [
      'terms',
      'distribution.batch'
    ];
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, relations).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case.terms                  = result.data.terms;
          this.case.case_creditor_entities = result.data.case_creditor_entities;
          this.case.distribution           = result.data.distribution;
        },
      ),
    );
  }

  public openCourtModal() {
    this.dialog.open(AppointCourtModalComponent, {
      width: '50%',
      data: {
        case: this.case,
      },
    });
  }
}
