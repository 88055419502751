<div class="row mt-3">
  <div class="col-12">
    <!--Court-->
    <button mat-raised-button color="primary" class="mb-1 mr-md-4" (click)="openCourtModal($event)">
      {{ 'CASES.single.draft.appoint_court.heading' | translate }}
    </button>
    <!-- Appointed Entities -->
    <button mat-raised-button color="primary" class="ml-2" [matMenuTriggerFor]="entitiesList">
      {{ 'CASES.single.draft.actions.entity_appointer_button' | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #entitiesList="matMenu">
      <button mat-menu-item class="mb-1 mr-md-4" (click)="selectCaseCreditorEntity('administrator')">
        {{ 'CASE_ENTITY.administrator.model_name.singular' | translate}}
      </button>
      <button mat-menu-item class="mb-1 mr-md-4" (click)="selectCaseCreditorEntity('notary')">
        {{ 'CASE_ENTITY.notary.model_name.singular' | translate}}
      </button>
      <button mat-menu-item class="mb-1 mr-md-4" (click)="selectCaseCreditorEntity('solicitor')">
        {{ 'CASE_ENTITY.solicitor.model_name.singular' | translate}}
      </button>
    </mat-menu>
    <!--  Download right of access  -->
    <button mat-raised-button color="primary" class="ml-4 mb-1 mr-md-4" (click)="getRightOfAccess('client', caseCreditor.id, $event)">
      {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
    </button>
    <!--  Download extrajudicial claim  -->
    <button mat-raised-button color="primary" class="mb-1 mr-md-4" (click)="getExtrajudicialClaim('client', caseCreditor.id, $event)">
      {{ 'CASES.single.creditors.actions.extrajudicial_claim' | translate }}
    </button>
    <!--  AEPO  Complaint  -->
    <button [matMenuTriggerFor]="aepdComplaint" mat-raised-button color="primary" class="ml-2 mt-2">
      {{'CASES.single.creditors.actions.aepd-complaint.label' | translate}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #aepdComplaint="matMenu">
      <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="generateAEPDComplaint(caseCreditor.id)">
       {{'CASES.single.creditors.actions.aepd-complaint.options.generate-aepd' | translate}}
      </button>
      <button mat-menu-item (click)="downloadAEPDComplaint(caseCreditor.id)" >
        {{'CASES.single.creditors.actions.aepd-complaint.options.download-aepd' | translate}}
      </button>
    </mat-menu>
    <button mat-raised-button color="primary" class="ml-4 mb-1 mr-md-4"  (click)="generateDemand($event)">
        Generate Demand
    </button>
  </div>

  <div class="col-12">
    <div class="pt-3 mb-5" *ngIf="appointedCourt && caseCreditor">
      <app-appointed-court-editor [appointedCourt]="appointedCourt"
                                  [caseCreditor]="caseCreditor"
                                  (removedAppointedCourt)="removeAppointedCourt($event)">
      </app-appointed-court-editor>
    </div>
  </div>
</div>
